export const environment = {
  production: false,
  RECAPTCHA_V3_SITE_KEY: '6LcQG5IhAAAAAGBXEYKwSWAFy6Obcu44qYRm2t1l',
  razorPayID: 'rzp_test_xtkRYZVkqJsKlD',
  stripeID: 'pk_test_51LNW5LJ17IHshykoGSeivjxdI3b34kZ1MbqwoOb2dselkqSrh1nYtqWgdmNJGFSfkM7obYss6io0iZSEcxmdUtH7008j7RWQde',
  dialog360ID: '805HUxPA',
  firebase: {
    apiKey: "AIzaSyB76kOK6DIoik5N8H6W2SzPMB95YwHObpk",
    authDomain: "sobot-d9428.firebaseapp.com",
    projectId: "sobot-d9428",
    storageBucket: "sobot-d9428.appspot.com",
    messagingSenderId: "878353671320",
    appId: "1:878353671320:web:d9fbc24327110d7b441ced",
    measurementId: "G-MCYGWM2295",
  },
  FCM_VapidKey: "BEqZwDpYTjJrra8-s20DD1jTvVj2wfX21xU66AJ6iITZ1R0fZCArycCn3TCxU3GCGWOim-esCCvOVgT34gpw6qM",
  posthog: 'phc_zOgSmPaGZu1xAp3L3yogLlQaFjYt7YLS1MGKZJAhre6',
  configuration: "Vibtree",
  partner: 'sobot',
  API_URL: 'dev-api.sobot.in',
  // API_URL: 'evolved-powerful-tick.ngrok-free.app',
  UI_BASE_URL: 'dev.sobot.in',
  favicon: './assets/sobot/logo.png',
  https: true,
};
